import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:9001/api';

const fetchData = async function (url) {
	return await axios(url, { baseURL, withCredentials: true });
}

const postData = async function (url, data) {
	return await axios({ url, data, baseURL, method: 'POST', withCredentials: true });
}

const putData = async function (url, data) {
	return await axios({ url, data, baseURL, method: 'PUT', withCredentials: true });
}

async function addRoundToGame(game_id, player_id, round_number, points, did_kabool) {
	return await postData(`/games/${game_id}/rounds`, { player_id, round_number, points, did_kabool });
}

async function setRoundInGame(game_id, player_id, round_number, points, did_kabool) {
	return await putData(`/games/${game_id}/rounds`, { player_id, round_number, points, did_kabool });
}

async function finishGame(game_id) {
	return await postData(`/games/${game_id}/finish`, {});
}

async function createGame(players, date, guestPlayers) {
	let body = {};
	body.game_date = date;
	body.players = players.map(x => ({ player_id: x.id }));
	body.guestPlayers = guestPlayers.map(x=>({name:x.name}));

	let result = await postData('/games', body);
	return result.data.id;
}

async function getGames() {
	let game = (await fetchData('/games?finishedOnly=false')).data;
	return game;
}

async function getGame(game_id) {
	let game = (await fetchData('/games/' + game_id)).data;
	return game;
}

async function getStatsByPlayerId(player_id) {
	let stats = (await fetchData('/stats/players/' + player_id)).data;
	return stats;
}

async function getStats() {
	let stats = (await fetchData('/stats')).data;
	return stats;
}

async function getLogin() {
	let user = (await fetchData('/loginInfo')).data;
	return user;
}

async function getUsersWithUsername(username) {
	let users = (await fetchData('/users?username=' + username)).data;
	return users;
}

function getLink(username) {
	return baseURL + '/users/' + username + '/link';
}

async function linkToUser(username) {
	return await fetchData('/users/' + username + '/link');
}

async function logout() {
	return await fetchData('/logout');
}

export default { 
	setRoundInGame,
	addRoundToGame,
	getGame,
	getGames,
	createGame,
	finishGame,
	getStatsByPlayerId,
	getStats,
	getLogin,
	getUsersWithUsername,
	logout,
	fetchData,
	postData,
	putData,
	linkToUser,
	getLink,
	baseURL 
};