import React from 'react';

const baseStyle = {
	padding: '1em'
}

const lineStyle = {
	display: 'flex',
	justifyContent: 'space-between'
}
const colorKabools ={
	color: '#ECA436'
}


export default function BasicList(props) {

	return (
		<div style={baseStyle}> 
			{props.items.map((item, index) => 
				<div key={index} style={lineStyle}><p style={index === 0? colorKabools : null}>{item.name}</p><p>{item.value}</p></div>	
			)}
		</div>
	)
}
