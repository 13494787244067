import React, {useState, useEffect, useRef} from 'react';
import GameRow from './GameRow';
import Footer from '../home/Footer';
import Game from './Game';
import APIAccessor from '../../util/APIAccessor';
import { useHistory } from 'react-router-dom';
import Loading from '../shared/Loading';

const gamesListStyle = {
	width: '100%',
	height: '100%',
	overflow: 'scroll'
}

const ulStyle = {
	listStyle: 'none'
}

const liStyle = { 
	padding: '0.7em 0em 0.3em 0em'
};

function Games(props) {
	const [games, setGames] = useState([]);
	const [loading, setLoading] = useState(true);

	const history = useHistory();
	const gameFromUrl = history.location.pathname.split('/')[2];

	useEffect(() => {
		APIAccessor.getGames()
		.then((result) => {
			setGames(result);
			setLoading(false);
		}).catch((err) => {
			console.error(err);
		});
	}, []);


	const [game, setGame] = useState(gameFromUrl || null);
	useEffect(() => {
		if (gameFromUrl) {
			setGame(gameFromUrl);	
		}
	}, [gameFromUrl]);
	

	const listRef = useRef();
	const [listScrollTop, setListScrollTop] = useState(0);
	let currentScroll = listScrollTop;

	useEffect(() => {
		if (listRef.current) {
			listRef.current.scrollTop = listScrollTop;	
		} 
	});

	function onItemClicked(game_id) {
		setListScrollTop(currentScroll);
		setGame(game_id);
		history.push('/games/' + game_id);
	}

	function onGameNotFound() {
		history.replace('/games');
		setGame(null);
	}

	function handleBack() {
		console.log(listRef.current);
		setGame(null);
		history.push('/games');
		APIAccessor.getGames()
		.then((result) => {
			setGames(result);
		}).catch((err) => {
			console.error(err);
		});
	}

	function handleOnCloseGame() {
		if (game === props.currentGame) {
			props.onCloseGame()
		}
	}

	function handleScroll(e) {
		currentScroll = listRef.current.scrollTop;
	}

	if (loading === true) {
		return  <Loading onBack={props.onBack}/>
	}

	if (game !== null) {
		return <Game game_id={game} onBack={handleBack} onCloseGame={handleOnCloseGame} hideDoneButton={game !== props.currentGame} onGameNotFound={onGameNotFound}/>
	}
	return (
		<div className="cardArea" >
			<div style={gamesListStyle} ref={listRef} onScroll={handleScroll}>
				<ul style={ulStyle}>
					{
						games.map( game => (<li style={liStyle} key={game.game_id}><GameRow game={game} currentGame={props.currentGame} onItemClicked={onItemClicked}/></li>))
					}
				</ul>
			</div>
			<Footer acceptHidden={true} onBack={props.onBack}/>
		</div>
		
	)
	
}
export default Games;