import React, { useState } from 'react'
import { COLOR_KABOOL, COLOR_STANDARD_TEXT, COLOR_STANDARD_BUTTON, ICON_CHECK_MARK } from '../../util/constants';

const rowStyle = {
  display: 'flex',
}

const setPointsStyle = {
  display: 'flex',
  flexDirection: 'column',
  height: '90%',
  width: '100%',
  justifyContent: 'space-around'
}

const pointButtonRowStyle = {
  display: 'flex',
  justifyContent: 'space-evenly',
  height: '3em'
}

const pointButtonStyle = {
  color: COLOR_STANDARD_BUTTON,
  height: '100%',
  width: '100%',
  textAlign: 'center'
}

const checkMarkBaseStyle = { width: '100%', textAlign: 'center' }

export default function SetPoints({ onDone, name, points, did_kabool }) {
  const [num, setNum] = useState(points ? points : 0);
  const [kabool, setKabool] = useState(did_kabool ? did_kabool : false);
  const [pickCards, togglePickCards] = useState(true);
  const [cards, setCards] = useState([]);

  function onPickCards() {
    togglePickCards(!pickCards);
  }

  function onAddPoints() {
    setNum(num + 1);
  }

  function onSetPoints(points) {
    setNum(points);
  }


  function onSubtractPoints() {
    let newNum = num - 1 < -4 ? -4 : num - 1;
    setNum(newNum);
  }

  function doneClicked(event) {
    event.stopPropagation();
    onDone(num, kabool);
  }

  function toggleKabool() {
    setKabool(!kabool);
  }

  // adds the number of points based on the card clicked
  function handleCardClick(cardNum, rank) {
    setCards([...cards, rank]);
    setNum(num + cardNum);

  }

  const ranks = ['Red K','A','2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K','JOKER'];
  const pointsPerCard = [-1,1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 10, 10, 10, 0];

  return (
    <>
      <div style={setPointsStyle} onClick={e => { e.stopPropagation() }}>
        <div className='centeredBtn doneBtn' onClick={onPickCards}>{pickCards? 'classic input' : 'PICK CARDS (new)'}</div>
        {pickCards? 
        <div style={{ padding: '1em' }}>
          <p style={{ marginBottom: '1em', paddingBottom: '1em' }}>PLAYER's CARDS:</p>
          <div style={{ marginBottom: '1em' }}>
            {cards.map((card, index) => (
              <span key={index} style={{ marginRight: '0.5em' }}>{card}</span>
            ))}
          </div>
          
          <div className="centeredBtn" style={{ marginBottom: '1em', paddingBottom: '1em'}}>
            <p>POINTS: {num}</p>
          </div>
          
          <div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '1em' }}>
              {ranks.map((rank, index) => (
                <div key={index} className="card doneBtn" onClick={() => handleCardClick(pointsPerCard[index], rank)}>{rank}</div>
              ))}
            </div>
          </div>
          {/* a button to clear the selected cards*/}
            <div className="centeredBtn doneBtn" style={{ marginTop: '3em' }} onClick={() => {
              setCards([]);
              setNum(0);
            }
            }>CLEAR</div>
        </div>
        :
        <div>
          <p>SET POINTS FOR PLAYER: {name}</p>
          <div style={{ ...rowStyle, justifyContent: 'space-around', height: '15vh' }}>
            <div onClick={onSubtractPoints} className="centeredBtn doneBtn">
              <p >-</p>
            </div>
            <div className="centeredBtn">
              <p>{num}</p>
            </div>
            <div onClick={onAddPoints} className="centeredBtn doneBtn" >
              <p >+</p>
            </div>
          </div>
          <div style={pointButtonRowStyle}>
            {
              [0, 5, 10, 15, 20, 30, 40].map(x => (<p style={pointButtonStyle} key={x} onClick={e => onSetPoints(x)}>{x}</p>))
            }
          </div>
        </div>
}
        <div style={{ ...rowStyle, justifyContent: 'space-evenly' }} onClick={toggleKabool}>
          <p style={{ width: '100%', textAlign: 'center' }} >SAID KABOOL?</p>
          <p style={kabool ? { ...checkMarkBaseStyle, color: COLOR_KABOOL } : { ...checkMarkBaseStyle, color: COLOR_STANDARD_TEXT }}>{ICON_CHECK_MARK}</p>
        </div>
      </div>
      <div className="centeredBtn doneBtn" style={{ marginTop: '2em' }}>
        <p onClick={doneClicked}>DONE</p>
      </div>
    </>
  )
}
