import React, { useState } from 'react';
import {animated, useSpring} from 'react-spring';
import { screens } from '../../util/constants';

const cardMovement = [
	{inForeground: 'translate3d(0px, 0px, 0px)', hidden: 'translate3d(-400px, 10px, 0px)', zIndex: '4'},
	{inForeground: 'translate3d(-140vw, 0px, 0px)', hidden: 'translate3d(400px, 10px, 0px)', zIndex: '3'},
	{inForeground: 'translate3d(0px, -240vh, 0px)', hidden: 'translate3d(-400px, 10px, 0px)', zIndex: '2'},
	{inForeground: 'translate3d(-140px, -240vh, 0px)', hidden: 'translate3d(400px, 10px, 0px)', zIndex: '1'},
];

const cardBasisStyle = {
	// golden ratio: 1.6180 - card ratio: 1.6279069767
	minWidth: '8.6em',
	minHeight: '14em',
	width: '31vw',
	height: '14em',
	maxWidth: '9em',
	maxHeight: '14em',
    backgroundColor: 'white',
    backgroundSize: 'auto 92%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
	boxShadow: '-3px 3px 7px rgba(0,0,0,0.26)',
	borderRadius: '0.3em',
	position: 'relative'
}

const textStyle = {
	fontFamily: "'Arial', sans-serif",
	fontSize: '14px',
    fontWeight: 'bold',
	color: '#707070',
	margin: 'unset',
	textAlign: 'center',
	marginTop: '0.5em',
	textTransform: 'uppercase'
}

const groupStyles = {
	margin: 'auto',
	position: 'relative'
}



export default function Card(props) {

	const inForeground = props.screen === props.myScreen;
	const hidden = props.screen !== screens.home && !inForeground;
	
	const cardPhysicsConfig = {mass:3, tension:100, friction:20, clamp:true};
	
	const [fade, setFadeClick] = useSpring(() => ({opacity:1, from: {opacity: 0}, config:cardPhysicsConfig}));
	const [disappear, setDisappear] = useSpring(() => ({opacity:1, config:{mass:3, tension:250, friction:20, clamp:true}}));
	
	setFadeClick({
		width: inForeground ? '90vw': '31vw', 
		height: inForeground? '60vh':'32vh', 
		maxWidth: inForeground? '100em': '9em', 
		maxHeight: inForeground? '100em': '14em',
		transform: inForeground? cardMovement[props.id].inForeground :'translate3d(0px, 0px, 0px)'
	});
	setDisappear({
		transform: hidden? cardMovement[props.id].hidden : 'translate3d(0px, 0px, 0px)',
		zIndex: hidden? '-1': '1'
	});

	const backgroundImage = inForeground? '' : `url(${props.cardImg})`;
	const cardStyle = {...cardBasisStyle, ...fade, backgroundImage, zIndex:cardMovement[props.id].zIndex};
	
	const newGroupStyles = {...groupStyles, ...disappear};
	

	return (
		<animated.div style={newGroupStyles}>
			<animated.div 
				onClick={e=> props.onCardClicked(props.id)} 
				style={cardStyle}
			>
				{ inForeground? props.children : null}
			</animated.div>
			{!hidden && !inForeground? <p style={textStyle}>{props.text}</p> : null}
		</animated.div>
	)
};