import React, { useState } from 'react';
import BasicList from '../shared/BasicList';
import Footer from '../home/Footer';
import { useEffect } from 'react';
import APIAccessor from '../../util/APIAccessor';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Loading from '../shared/Loading';

const areaStyle = {
	height: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	flexDirection: 'column'
}


export default function Profile({user, onBack, onLogout}) {
	const location = useLocation();
	location.query = queryString.parse(location.search);

	const [playerStats, setPlayerStats] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (user) {
			APIAccessor.getStatsByPlayerId(user.id)
			.then((result) => {
				setPlayerStats(result);
				setLoading(false);
			}).catch((err) => {
				alert(err.response);
			});
		}
	}, [user]);

	function handleLogoutClick() {
		onLogout();
	}

	function handleFocus(e) {
		e.target.select();
		e.preventDefault();
	}

	function handleChange(e) {
		e.preventDefault();
		console.log(e.target);
	}

	const [showLink, setShowLink] = useState(false);
	function handleShowLink(e) {
		setShowLink(!showLink);
	}

	if (loading) {
		return  <Loading onBack={onBack}/>
	}

	return (
		<div className="cardArea">
			<div style={areaStyle}>
			<BasicList items={playerStats.map(x=>({name:x.displayName, value:x.value}))}/>
				{
					!showLink ?
					<div onClick={handleShowLink} className="doneBtn centeredBtn"><p style={{fontSize:'14px'}}>CONNECT WITH OTHER USERS</p></div> :
					<div style={{padding: '1em'}}>
						<div className="linkText">To link with other users, share the following link:</div>
						<textarea onFocus={handleFocus} onChange={handleChange} value={APIAccessor.getLink(user.name)}></textarea>
					</div>
				}

				<div className="centeredBtn warnBtn"><p style={{fontSize:'13px'}} onClick={handleLogoutClick}>Logout</p></div>
			</div>
			<Footer onBack={onBack} acceptHidden={true}/>
		</div>	
	)
}
