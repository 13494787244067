import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string';

export default function PrivacyPage() {

  const location = useLocation();
  console.log('TESTETESTETS');
  location.query = queryString.parse(location.search);
  console.log(location.query);

  return (
    <div style={{ padding: '0.5em' }}>
      <h1>Privacy Policy</h1>

      <p>Last updated: May 03, 2020</p>

      <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information
	when You use the Service and tells You about Your privacy rights and how the law protects You.</p>

      <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and
	use of information in accordance with this Privacy Policy. </p>

      <h1>Interpretation and Definitions</h1>
      <h2>Interpretation</h2>
      <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.</p>
      <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

      <h2>Definitions</h2>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li>
          <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal
			entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
        </li>
        <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.
	</li>
        <li><strong>Website</strong> refers to KaboolApp, accessible from <a href="https://kabool.jmegas.com">https://kabool.jmegas.com</a></li>
        <li><strong>Service</strong> refers to the Website.</li>
        <li><strong>Country</strong> refers to: Baden-Württemberg, Germany</li>
        <li>
          <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of
			KaboolApp. It refers to third-party companies or individuals employed by KaboolApp to facilitate the
			Service, to provide the Service on behalf of KaboolApp, to perform services related to the Service or to
			assist KaboolApp in analyzing how the Service is used.</p>
        </li>
        <li><strong>Third-party Social Media Service</strong> refers to any website or any social network website through
		which a User can log in or create an account to use the Service.</li>
        <li>
          <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
		</p>
        </li>
        <li><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by
		a website, containing the details of Your browsing history on that website among its many uses.</li>
        <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a
		digital tablet.</li>
        <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service
		or from the Service infrastructure itself (for example, the duration of a page visit).</li>
      </ul>

      <h1>Collecting and Using Your Personal Data</h1>
      <h2>Types of Data Collected</h2>

      <h3>Personal Data</h3>
      <p>The personal data we collect when using our services is limited to:</p>
      <ul>
        <li>Social Media ID</li>
        <li>Usage Data</li>
      </ul>
      <strong>We do not store any information linked to your social media account apart form your social media account ID. </strong>

      <h2>How do we collect your personal data</h2>
      <p>KaboolApp only collects the personal data described above when you create an account by logging in with your social media account</p>

      <h2>What are your data protection rights?</h2>
      <p>KaboolApp would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
      <p>The right to access – You have the right to request KaboolApp for copies of your personal data. We may charge you a small fee for this service.</p>
      <p>The right to rectification – You have the right to request that KaboolApp correct any information you believe is inaccurate. You also have the right to request KaboolApp to complete the information you believe is incomplete.</p>
      <p>The right to rectification – You have the right to request that KaboolApp correct any information you believe is inaccurate. You also have the right to request KaboolApp to complete the information you believe is incomplete.</p>
      <p>The right to erasure – You have the right to request that KaboolApp erase your personal data, under certain conditions.</p>
      <p>The right to restrict processing – You have the right to request that KaboolApp restrict the processing of your personal data, under certain conditions.</p>
      <p>The right to object to processing – You have the right to object to KaboolApp’s processing of your personal data, under certain conditions.</p>
      <p>The right to data portability – You have the right to request that KaboolApp transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
      <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: <a href="mailto:kaboolapp@gmail.com">kaboolapp@gmail.com</a> </p>

      <h3>Usage Data</h3>
      <p>Usage Data is collected automatically when using the Service.</p>
      <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type,
      browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those
	pages, unique device identifiers and other diagnostic data.</p>
      <p>When You access the Service by or through a mobile device, We may collect certain information automatically,
      including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of
      Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device
	identifiers and other diagnostic data.</p>
      <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service
	by or through a mobile device.</p>



      <h3>Tracking Technologies and Cookies</h3>
      <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information.
      Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and
	analyze Our Service.</p>
      <p>You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do
	not accept Cookies, You may not be able to use some parts of our Service.</p>
      <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile
      device when You go offline, while Session Cookies are deleted as soon as You close your web browser. Learn more
	about cookies in the <a href="https://www.privacypolicies.com/blog/cookies/">"What Are Cookies"</a> article.</p>
      <p>We use both session and persistent Cookies for the purposes set out below:</p>
      <ul>
        <li>
          <p><strong>Necessary / Essential Cookies</strong></p>
            <p>Type: Session Cookies</p>
            <p>Administered by: Us</p>
            <p>Purpose: These Cookies are essential to provide You with services available through the Website and to
            enable You to use some of its features. They help to authenticate users and prevent fraudulent use of
            user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We
				only use these Cookies to provide You with those services.</p>
        </li>
        <li>
          <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
          <p>Type: Persistent Cookies</p>
          <p>Administered by: Us</p>
          <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
        </li>
        <li>
          <p><strong>Functionality Cookies</strong></p>
          <p>Type: Persistent Cookies</p>
          <p>Administered by: Us</p>
          <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering
          your login details or language preference. The purpose of these Cookies is to provide You with a more
			personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
        </li>
        <li>
          <p><strong>Statistics Cookies</strong></p>
          <p>Type: Persistent Cookies</p>
          <p>Administered by: Third-Parties</p>
          <p>Purpose: These Cookies are used to track information about traffic to the Website and how users use the
          Website. The information gathered via these Cookies may directly or indirectly identify you as an individual
          visitor. This is because the information collected is typically linked to a pseudonymous identifier
          associated with the device you use to access the Website. We may also use these Cookies to test new
			advertisements, pages, features or new functionality of the Website to see how our users react to them.</p>
        </li>
      </ul>
      <h2>How to manage your cookie preference</h2>
      <p>"Essential", "notice acceptance" and "functionality" cookies are required for the use of our service.</p>
      <p> You can opt out from
	statistics cookies any time <a href={'/consent' + location.search}>here</a></p>

      <h2>Use of Your Personal Data</h2>
      <p>KaboolApp may use Personal Data for the following purposes:</p>
      <ul>
        <li><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</li>
        <li><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data
		You provide can give You access to different functionalities of the Service that are available to You as a
		registered user.</li>
      </ul>

      <p><strong>We do not share any collected personal information.</strong></p>

      <h2>Retention of Your Personal Data</h2>
      <p>KaboolApp will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy
      Policy. Generally, for as long as your account is active. We will retain and use Your Personal Data to the extent
      necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with
	applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
      <p>KaboolApp will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for 30 days,
      except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are
	legally obligated to retain this data for longer time periods.</p>

      <h2>Transfer of Your Personal Data</h2>
      <p>Your information, including Personal Data, is processed at KaboolApp's operating offices and in any other places
      where the parties involved in the processing are located. It means that this information may be transferred to — and
      maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where
	the data protection laws may differ than those from Your jurisdiction.</p>
      <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that
	transfer.</p>
      <p>KaboolApp will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance
      with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country
	unless there are adequate controls in place including the security of Your data and other personal information.</p>

      <h2>Disclosure of Your Personal Data</h2>
      <h3>Business Transactions</h3>
      <p>If KaboolApp is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will
	provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
      <h3>Law enforcement</h3>
      <p>Under certain circumstances, KaboolApp may be required to disclose Your Personal Data if required to do so by law or
	in response to valid requests by public authorities (e.g. a court or a government agency).</p>
      <h3>Other legal requirements</h3>
      <p>KaboolApp may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
      <ul>
        <li>Comply with a legal obligation</li>
        <li>Protect and defend the rights or property of KaboolApp</li>
        <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
        <li>Protect the personal safety of Users of the Service or the public</li>
        <li>Protect against legal liability</li>
      </ul>

      <h2>Security of Your Personal Data</h2>
      <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet,
      or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your
	Personal Data, We cannot guarantee its absolute security.</p>

      <h1>Detailed Information on the Processing of Your Personal Data</h1>
      <p>Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not
	to disclose or use it for any other purpose.</p>

      <h2>Analytics</h2>
      <p>We may use third-party Service providers to monitor and analyze the use of our Service.</p>
      <ul>
        <li>
          <p><strong>Azure Monitor (Application Insights)</strong></p>
          <p>Application Insights is a feature of Azure Monitor, a Cloud analytics service. Read the Privacy and Retention Policy for Application Insights <a
            href="https://docs.microsoft.com/en-us/azure/azure-monitor/app/data-retention-privacy">here</a>.</p>
        </li>
      </ul>

      <h1>Children's Privacy</h1>
      <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable
      information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has
      provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from
      anyone under the age of 13 without verification of parental consent, We take steps to remove that information from
	Our servers.</p>
      <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from
	a parent, We may require Your parent's consent before We collect and use that information.</p>



      <h1>Links to Other Websites</h1>
      <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You
      will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You
	visit.</p>
      <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third
	party sites or services.</p>

      <h1>Changes to this Privacy Policy</h1>
      <p>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy
	Policy on this page.</p>
      <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and
	update the "Last updated" date at the top of this Privacy Policy.</p>
      <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
	effective when they are posted on this page.</p>

      <h1>Contact Us</h1>
      <p>If you have any questions about this Privacy Policy, You can contact us:</p>
      <ul>
        <li>By email: kaboolapp@gmail.com</li>
      </ul>
      {
        location.query.mobile === 'true'? '':
        <a href={'/home' + location.search}><div className="centeredBtn doneBtn"><p>BACK HOME</p></div></a>
      }
      

    </div>
  )
}
