import React from 'react'
import Footer from '../home/Footer'

export default function NoLogin({text, onBack}) {
	return (
		<div className="cardArea">
			<p>Please log in to {text}</p>
			<Footer onBack={onBack} acceptHidden={true}/>
		</div>
	)
}
