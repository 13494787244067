export const COLOR_KABOOL = '#ECA436';
export const COLOR_STANDARD_TEXT = '#707070';
export const COLOR_STANDARD_WHITE = '#F8F6F2';
export const COLOR_STANDARD_BUTTON = '#128DC5';
export const COLOR_STANDARD_BLACK = '#020202';
export const COLOR_STANDARD_DANGER = '#D20343';

export const ICON_CHECK_MARK = '✓';

export const screens = {
	home: 'home',
	game: 'game',
	createGame: 'createGame',
	stats: 'stats',
	games: 'games',
	profile: 'profile'
}