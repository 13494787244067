import React, { useEffect, useState } from 'react'
import Footer from '../home/Footer'
import {useHistory, useLocation} from 'react-router-dom';
import GoogleIcon from './btn_google_light_normal.svg';
import GoogleIconDisabled from './btn_google_light_disabled.svg';
import { ICON_CHECK_MARK, COLOR_KABOOL, COLOR_STANDARD_TEXT, COLOR_STANDARD_DANGER } from '../../util/constants';
import APIAccessor from '../../util/APIAccessor';
import queryString from 'query-string';

export default function Register(props) {
	const location = useLocation();
	location.query = queryString.parse(location.search);

	const history = useHistory();
	useEffect(() => {
		history.replace('/profile/register' + location.search);
	}, [history]);

	const [username, setUsername] = useState('');
	const isValid = function(username) {
		return /^[A-Za-z0-9]{3,10}$/.test(username);
	}
	
	const [isAvailable, setIsAvailable] = useState(null);
	const [counter, setCounter] = React.useState(0);
	useEffect( () => {
		const i_id = setInterval(() => {
		  setCounter(currCount => currCount === -1? -1 : currCount -1);
		  if (counter === 0 && isValid(username)) {
			console.log('CHECKING USERNAME AVAILABILITY FOR' + username); 
			APIAccessor.getUsersWithUsername(username)
			.then((result) => {
				console.log(result.length === 0);
				let available = result.length === 0;
				setIsAvailable(available);
			})
			.catch((error) => {
				console.error(error);
			});
		  }
		  
		},1000);
		return () => {
		  clearInterval(i_id);
		}
	  },[counter, username]);

	
	function handleChange(e) {
		let inputString = '' + e.target.value;
		inputString = inputString.trim().substr(0,10);
		if (inputString === username) {
			return;
		}
		setUsername(inputString);
		setIsAvailable(null);
		setCounter(0);
	}

	const [usernameValid, setValid] = useState(false);
	useEffect(() => {
		setValid(isValid(username));
		
	}, [username])
	function handleClick(e){
		if (usernameValid && isAvailable) {
			let baseUrl = process.env.REACT_APP_BASE_URL + '/auth/google?type=signup&username=' + username;
			if (location.query.next) {
				baseUrl = baseUrl + '&next=' + location.query.next;
			}
			window.location.replace(baseUrl);
		}
	}

	return (
		<div className="cardArea">
			<div style={{display:'flex', flexDirection:'column', height:'100%', alignItems:'center', justifyContent:'space-around'}}>
				<p>First, choose a username:</p>
				<div >
				<input type="text" style={{width:'90px'}} value={username} name="username" id="username" onChange={handleChange}/>
				<span style={{
					width:'20px',
					color: isValid(username)? isAvailable === null? COLOR_STANDARD_TEXT : isAvailable === false? COLOR_STANDARD_DANGER : COLOR_KABOOL : COLOR_STANDARD_TEXT,
					visibility: username.length < 3 ? 'hidden' : 'visible'
					}}>
					{isAvailable === null? 'x' : isAvailable === false && isValid(username) ? 'x' : ICON_CHECK_MARK}
				</span>
				</div>
				<p>Then, sign up with:</p>
				<div className="signInBtn" onClick={handleClick}>
					<span className="signInIcon" style={{backgroundImage: `url(${usernameValid && isAvailable? GoogleIcon : GoogleIconDisabled})`}} ></span>
					<span className="signInBtnText" style={{color: usernameValid && isAvailable? '#444' : '#999999'}} >Google</span>
				</div>
				<p style={{fontSize: '12px'}}>Already have an account? <span className="doneBtn" onClick={props.onLoginClicked}>Log in</span></p>
				<p style={{fontSize: '12px'}}>By registering your agree to our <span className="doneBtn"><a href="/privacy">privacy policy</a></span></p>
			</div>
			<Footer onBack={props.onBack} acceptHidden={true}/>
		</div>
	)
}
