import React, { useState, useEffect } from 'react';
import Title from '../components/shared/Title';
import Subtitle from '../components/shared/Subtitle';
import CardGrid from '../components/home/CardGrid';
import HeaderImg from '../components/home/HeaderImg';
import {screens} from '../util/constants';
import APIAccessor from '../util/APIAccessor';
import {useLocation, useHistory} from 'react-router-dom';
import queryString from 'query-string';
import PrivacyPage from '../components/privacy/PrivacyPage';
import Consent from '../components/privacy/Consent';
import Rules from '../components/rules/Rules';

const style = {
	paddingLeft: '1.4em',
	paddingRight: '1em',
	paddingTop: '1em'
}

const headerStyles = {
	display: 'flex',
	paddingLeft: '1em',
	paddingRight: '1em',
	paddingTop: '1em'

}

function App() {

	console.log(`Starting KaboolApp with backend url: ${process.env.REACT_APP_BASE_URL}`);

	const location = useLocation();
	location.query = queryString.parse(location.search);
	const history = useHistory();

	console.log('LOCATION: ');
	console.log(location);

	const [user, setUser] = useState(null);
	useEffect(() => {
		console.log('STARTED');
		console.log(user);
		if (user === null) {
			APIAccessor.getLogin()
			.then((user) => {
				console.log('received user');
				console.log(user);
				setUser({id:user.player_id, name:user.username});
				checkNextAction();
			})
			.catch((error) => {
				console.log(error.message);
				removeGame();
			});
		}
	}, [user]);

	const [screen, setScreen] = useState(screens.home);

	function onScreenSelected(screenName) {
		if (screenName === screens.createGame && currentGame) {
			history.push('/game/' + currentGame);
		} else {
			history.push('/' + screenName);
		}
		setScreen(screenName);
		console.log('new screen selected: ' + screenName);
	}

	function checkNextAction() {
		const next = location.query.next;
		if (next && next.includes('link_')) {
			const username = next.split('_')[1];
			APIAccessor.linkToUser(username)
				.then((result) => {
					history.replace('/home');
					alert('linked to user ' + username);
				})
				.catch((error) => {
					alert('could not link to user ' + username);
				});
		}
	}

	useEffect(() => {
		console.log(location);
		if (location.pathname === '/registrationComplete') {
			console.log('REGISTRATION COMPLETE');
		} else if (location.pathname === '/failed') {
			alert('Login or registration failed');
		} else if (location.pathname.includes('/profile')) {
			setScreen(screens.profile);
		} else if (location.pathname === '/home') {
			setScreen(screens.home);
		} else if (location.pathname === '/linked') {
			if (location.query.username) {
				alert('linked to user ' + location.query.username);
				history.replace('/home');
			}
		} else if (location.pathname.includes('/games')) {
			setScreen(screens.games);
		} else if (location.pathname === '/stats') {
			setScreen(screens.stats);
		} else if (location.pathname === '/createGame') {
			setScreen(screens.createGame);
		} else if (location.pathname.includes('/game/')) {
			if (!currentGame) {
				const game_id = location.pathname.split('/')[2];
				history.replace('/games/' + game_id);
			}
			setScreen(screens.createGame);
		}
	}, [location]);
	

	let storedGame = window.localStorage.getItem('game');
	const [currentGame, setCurrentGame] = useState(storedGame? storedGame: null);
	const onCreateGame = function (players, date, guestPlayers) {
		console.log({players, date, guestPlayers});
		APIAccessor.createGame(players, date, guestPlayers)
		.then((game_id) => {
			window.localStorage.setItem('game', game_id);
			setCurrentGame(game_id);
		})
		.catch((error) => {
			console.error(error);
		});
	}

	function removeGame() {
		window.localStorage.removeItem('game');
		setCurrentGame(null);
	}

	const onCloseGame = function () {
		removeGame();
		setScreen(screens.home);
	}
	
	function onUserSelected(user) {
		let usr = {id:user.id, name:user.name};
		setUser(usr);
		window.localStorage.setItem('user', JSON.stringify(usr));
	}

	function onLogout() {
		APIAccessor.logout()
		.then(() => {
			console.log('logged out');
			setScreen(screens.home);
			setUser(null);
			removeGame();
		})
		.catch((error) => {
			console.error(error);
		});
	}

	console.log('APP STATE :');
	console.log({user, screen, game: currentGame});

	const onBack = function () {
		console.log('Back pressed. Current screen: ' + screen);
		history.push('/home' + location.search);
	}

	if (location.pathname === '/privacy') {
		return <PrivacyPage/>
	}

  if (location.pathname === '/rules') {
    return <Rules/>
  }

	const consentGiven = window.localStorage.getItem('consent') === 'true';
	if (!consentGiven || location.pathname === '/consent') {
		return <Consent />
	}

	
	
	return (
		<div style={style}>
			<div style={headerStyles}>
				<div>
					<Title screen={screen} onBack={onBack}></Title>
					<Subtitle screen={screen} user={user} game={currentGame}/>
				</div>
				<HeaderImg screen={screen}/>
			</div>
			<CardGrid 
				onScreenSelected={onScreenSelected} 
				onCreateGame={onCreateGame}
				onCloseGame={onCloseGame}
				onUserSelected={onUserSelected} 
				currentGame={currentGame}
				user={user}
				screen={screen}
				onLogout={onLogout}
				onBack={onBack}
			/>
			<div className="centeredBtn doneBtn"><a href='/rules'>how to play?</a></div>
		</div>
	);
}

export default App;
