import React from 'react'
import PlayersList from '../newgame/PlayersList'

export default function MultiplePlayerRemove({list}) {

	function onRemove(player_id) {
		list.deselect(player_id);
	}
	
	return (
		<PlayersList players={list.getSelected().map(x=>({...x, removable: true}))} onRemove={onRemove}/>
	)
}
