import React from 'react'
import Login from './Login'
import Register from './Register';
import { useState } from 'react'

export default function LoginOrRegister(props) {

	const [login, setLogin] = useState(false);

	function toggleLogin() {
		console.log('toggleLogin');
		
		setLogin(!login);
	}

	if (login) {
		return <Login onBack={props.onBack} onRegisterClicked={toggleLogin}/>
	}

	return <Register onBack={props.onBack} onLoginClicked={toggleLogin}/>
}
