import React, { useEffect, useState } from 'react';
import BasicList from '../shared/BasicList';
import Footer from '../home/Footer';
import APIAccessor from '../../util/APIAccessor';
import Loading from '../shared/Loading';



export default function Stats({onBack}) {

	const [stats, setStats] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		APIAccessor.getStats()
		.then((result) => {
			const _stats = result.map(x=>({name:x.displayName, value:'', list:x.list})).map(
				(x) => {
					x.list.unshift({name:x.name});
					return x;
				}
			);
			setStats(_stats);
			setLoading(false);
		}).catch((err) => {
			console.log(err);
			alert(err.response);
		});
	
	}, []);

	if (loading) {
		return  <Loading onBack={onBack}/>
	}

	return (
		<div className="cardArea">
			<div style={{height:'100%', overflowY:'scroll'}}>
			<ul>
				{stats.map(x=>(
					<BasicList items={x.list}/>
				))}
			</ul>
			</div>
			<Footer onBack={onBack} acceptHidden={true}/>
		</div>
	)
}