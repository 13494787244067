import React, { useState } from 'react'

const rowStyles = {
	display: 'flex',
	justifyContent: 'space-between'
}

const dateTextStyle ={fontWeight: 'bold'}

const dateFieldStyle = {
	textDecorationLine: 'underline'
}

export default function DateRow({editable, defaultVal}) {

	const [date, setDate] = useState(defaultVal || new Date().toISOString().split('T')[0]);

	return (
		<div style={rowStyles}>
			<p style={dateTextStyle}>Date:</p>
			<p style={dateFieldStyle}>{date}</p>
		</div>
	)
}
