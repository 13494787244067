import React from 'react';
import { screens } from '../../util/constants';
import { useLocation } from 'react-router-dom';

const styles = {
	fontFamily: "'Arial', sans-serif",
	fontSize: '1.2em',
    fontWeight: 'bold',
	color: '#707070',
	margin: 'unset'
};

export default function Subtitle(props) {
	const location = useLocation();

	const content = {};
	content[screens.home] = 'PLAYER_NAME';
	content[screens.profile] = 'PLAYER_NAME';
	content[screens.createGame] = 'Add at least two players to start a game';
	content[screens.stats] = 'Top results across all games';
	content[screens.games] = 'Game history';

	if (props.screen === screens.home) {
		if (props.user !== null) {
			return <p style={styles}>Welcome, {props.user.name}</p>
		} else {
			return <p style={styles}>Keep track of your games</p>
		}
	}
	if (props.screen === screens.games || props.screen === screens.createGame){
		if (props.game !== null) {
			return <p style={styles}>Set players' points</p>
		}

	}
	if (props.screen === screens.profile) {
		if (location.pathname === '/profile/login') {
			return <p style={styles}>Log in</p>
		}
		if (location.pathname === '/profile/register') {
			return <p style={styles}>Create an account</p>
		}
		if (props.user !== null) {
			return <p style={styles}>{props.user.name}</p>
		} 
	}
	

	return (
		<p style={styles}>{content[props.screen]}</p>
	)
};