import React from 'react'
import BasicList from '../shared/BasicList'
import { COLOR_STANDARD_BUTTON, COLOR_STANDARD_TEXT } from '../../util/constants';

const gameRowStyles = {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '1em',
}

export default function GameRow({game, onItemClicked, currentGame}) {
	const items = [{},{},{},{}];
	let firstRow = {name:'Winner', value:game.winner === 'Tie'? 'tied game': game.winner};
	let secondRow = {name:'Points', value:game.final_points};
	if (!game.finished) {
		firstRow = {name:'Ongoing...', value:''}
		secondRow = {name:'Current best:', value:game.winner};
	}

	items[0] = firstRow;
	items[1] = secondRow;
	items[2] = {name:'Date', value:game.game_date.split('T')[0]};
	//items[3] = {name:'Id', value:game.game_id};

	const borderColor = parseInt(game.game_id) === parseInt(currentGame) ? COLOR_STANDARD_BUTTON : COLOR_STANDARD_TEXT+'3d';
	
	return (
		<div style={{...gameRowStyles, borderColor}} onClick={(event) => {onItemClicked(game.game_id); event.stopPropagation()}}>
			<BasicList items={items} />
		</div>
	)
}

