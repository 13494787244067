import React from 'react';
import Card from '../shared/Card';
import Profile from '../profile/Profile';
import Stats from '../stats/Stats';
import Games from '../games/Games';
import Game from '../games/Game';
import NewGame from '../newgame/NewGame';
import { screens } from '../../util/constants';


import kingCard from '../../img/card_king.png';
import duelCard from '../../img/card_duel.png';
import crownsCard from '../../img/card_crowns.png';
import scrollsCard from '../../img/card_scrolls.png';
import noLoginCard from '../../img/card_nologin.png';
import NoLogin from '../shared/NoLogin';
import LoginOrRegister from '../profile/LoginOrRegister';

const cardRowStyles = {
	display:'flex',
	marginBottom: '20px',
	paddingTop: '1 em'
}

const cardGridStyles = {
	marginTop: '3.4em',
	overflow: 'hidden',
}

const cardToScreen = [screens.profile, screens.createGame, screens.stats, screens.games];

export default function CardGrid(props) {
	

	const onCardClicked = function (id) {
		if (props.screen !== screens.home) {
			return;
		}
		props.onScreenSelected(cardToScreen[id]);
	}

	return (
		<div style={cardGridStyles}>
			<div style={cardRowStyles}>
				<Card 
				text={props.user ?'profile' : 'Login'}
				id={0} 
				cardImg={props.user ? kingCard : noLoginCard}
				myScreen={screens.profile}
				screen={props.screen}
				onCardClicked={onCardClicked}>
					{
						props.user? 
						<Profile user={props.user} onLogout={props.onLogout} onBack={props.onBack}/>:
						<LoginOrRegister onBack={props.onBack}/>
					}
				</Card>
				<Card 
				text={props.currentGame !== null? 'continue game' : 'new game'} 
				id={1}
				cardImg={duelCard}
				myScreen={screens.createGame}
				screen={props.screen}
				onCardClicked={onCardClicked}>
					{
						!props.user?
						<NoLogin text="create a new game" onBack={props.onBack}/>:
						props.currentGame === null?
						<NewGame onCreateGame={props.onCreateGame} onBack={props.onBack}/> :
						<Game game_id={props.currentGame} onBack={props.onBack} onCloseGame={props.onCloseGame}/>
					}
					
				</Card>
			</div>
			<div style={cardRowStyles}>
				<Card
				text={'Stats'}
				id={2}
				cardImg={crownsCard}
				myScreen={screens.stats}
				screen={props.screen}
				onCardClicked={onCardClicked}>
					{
						props.user? <Stats onBack={props.onBack}/> : <NoLogin text="see stats" onBack={props.onBack}/>
					}
				</Card>
				<Card
				text={'Games'}
				id={3}
				cardImg={scrollsCard}
				myScreen={screens.games}
				screen={props.screen}
				onCardClicked={onCardClicked}>
					{
						props.user? 
						<Games onGameSelected={props.onGameSelected} onBack={props.onBack} onCloseGame={props.onCloseGame} currentGame={props.currentGame}/>
						: <NoLogin text="see past and ongoing games" onBack={props.onBack}/>
					}
				</Card>
			</div>
		</div>
	)
};