import React from 'react';
import PlayersList from '../newgame/PlayersList';

export default function MultiplePlayerSelect({list}) {
	

	function onSelect(player_id) {
		list.select(player_id);
	}

	function onDeselect(player_id) {
		list.deselect(player_id);
	}

	return <PlayersList players={list.getAllMarkSelected().map(x=>({...x, selectable: !x.selected}))} onSelect={onSelect} onDeselect={onDeselect}/>
}
