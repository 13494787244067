import React from 'react'
import CardImg from './profileImg.png';
import { screens } from '../../util/constants';

const basisStyle = {
	minWidth: '6em',
	minHeight: '6em',
	maxWidth: '6em',
	maxHeight: '6em',
    backgroundColor: 'white',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center center',
	backgroundImage: `url(${CardImg})`,
	borderRadius: '5em',
	opacity: '1',
	position: 'relative',
	marginLeft: 'auto'
}

export default function HeaderImg(props) {

	let visibility = props.screen === screens.profile ? 'inherit' : 'hidden';

	return (
		<div style={{...basisStyle, visibility}}/>
	)
};