export default class MultiSelectList {
	constructor(players, startSelected, setFunc) {
		this.players = [];
		this.addAll(players, startSelected);
		this.setFunc = setFunc;
	}

	addAll(players, startSelected){
		this.players = this.players.concat(players.map(x=>({
			selected: startSelected,
			id:x.id,
			name:x.name
		})));
	}

	select(player_id){
		let playerIndex = this.players.findIndex(x => x.id === player_id);
		if (playerIndex !== -1) {
			this.players[playerIndex].selected = true;
			console.log('selected ');
			console.log(this.players[playerIndex]);
			this.setFunc(this.players.map(x => ({id:x.id, name:x.name})));
		}
	}
	deselect(player_id){
		let playerIndex = this.players.findIndex(x => x.id === player_id);
		if (playerIndex !== -1) {
			this.players[playerIndex].selected = false;
			console.log('deselected ');
			console.log(this.players[playerIndex]);
			this.setFunc(this.players.map(x => ({id:x.id, name:x.name})));
		}
	}

	getAllMarkSelected(){
		return this.players;
	}

	getSelected(){
		return this.players.filter(x=>x.selected).map(x => ({id:x.id, name:x.name}));
	}
	getAll(){
		return this.players.map(x => ({id:x.id, name:x.name}));
	}
};