import React from 'react'
import { COLOR_STANDARD_BUTTON } from '../../util/constants';


const footerStyles = {
	display: 'flex', 
	justifyContent: 'space-between',
	alignItems: 'baseline',
	padding: '0.5em 0.3em 0em 0.3em'
};

const backTextStyles = {
	textTransform: 'uppercase',
	fontSize: '14px'
}

const acceptTextStyles = {
	color: COLOR_STANDARD_BUTTON,
	textTransform: 'uppercase',
	fontSize: '21px',
	fontWeight: 'bold',
	letterSpacing: '-0.05em',
}

export default function Footer({acceptText, onAccept, backText, onBack, acceptHidden, backHidden}) {

	const handleBack = function (e) {
		e.stopPropagation();
		onBack();
	}

	return (
		<div style={footerStyles}>
			<div 
			onClick={handleBack}
			style={backHidden? {visibility:'hidden'}: null} >
				<p style={backTextStyles}> {backText? backText : `< back`} </p>
			</div>
			<div 
			onClick={onAccept}
			style={acceptHidden? {visibility:'hidden'} : null }>
				<p style={acceptTextStyles}>{acceptText? acceptText : 'done'}</p>
			</div>
		</div>
	)
}
