import {useState, useEffect} from 'react';
import api from './APIAccessor';
import APIAccessor from './APIAccessor';

export default function useGetFetch(url) {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);

		APIAccessor.fetchData(url)
		.then((result) => {
			setData(result.data);
			setLoading(false);
		}).catch((err) => {
			console.error(err.message);
		});
		
	}, [url]);

	return [loading, data];
};