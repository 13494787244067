import React from 'react'
import Footer from '../home/Footer'

export default function Loading({onBack}) {
	return (
		<div className="cardArea" style={{display:'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
			<p>Loading...</p>
			<Footer onBack={onBack} acceptHidden={true}/>
		</div>
	)
}
