import React from 'react'
import {animated, useSpring} from 'react-spring';

export default function PointsCell({points}) {
	const config = {precision:1, clamp:true};
	const spring = useSpring({ number: points, from: { number: 100 } , config});
	
	if (isNaN(points)) {
		return <span>{points}</span>;
	}
	
	return <animated.span>{spring.number.interpolate(number => Math.floor(number))}</animated.span>
}
