import React, { useEffect } from 'react';
import DateRow from '../shared/DateRow';
import { useState } from 'react';
import Footer from '../home/Footer';
import SelectPlayers from './SelectPlayers';
import Loading from '../shared/Loading';
import useGetFetch from '../../util/FetchHook';
import MultiSelectList from '../../util/MultiSelectList';
import PlayersList from './PlayersList';
import MultiplePlayerRemove from '../shared/MultiplePlayerRemove';

const playersHeader = {
	fontWeight: 'bold',
	marginTop: '1em',
	marginBottom: '0.3em'
}

export default function NewGame(props) {
	const [players, setPlayers] = useState([]);
	const [playersList, setPlayersList] = useState(null);

	const [loading, data] = useGetFetch('/players');
	useEffect(() => {
		let list = new MultiSelectList(data, false, setPlayers);
		setPlayersList(list);
	}, [data]);


	const [guestPlayers, setGuestPlayers] = useState([]);
	function addGuestPlayer(name) {
		setGuestPlayers([...guestPlayers, {name, id:guestPlayers.length}]);
	}
	function onRemoveGuest(id) {
		setGuestPlayers(guestPlayers.filter(x=>x.id !== id));
	}

	const [gameDate, setGameDate] = useState(new Date().toISOString().split('T')[0]);

	const [selectingPlayers, setSelectingPlayers] = useState(false);

	
	function enoughPlayersSelected() {
		console.log(playersList.getSelected().length);
		console.log(guestPlayers.length);
		
		
		return (playersList.getSelected().length + guestPlayers.length) > 1;
	}

	function onAccept() {
		props.onCreateGame(playersList.getSelected(), gameDate, guestPlayers);
	}

	const handleClickAddPlayers = function () {
		setSelectingPlayers(true)
	}

	const onFinishedSelecting = function (selectedPlayers) {
		setSelectingPlayers(false);
	}

	const onBack = function () {
		setSelectingPlayers(false);
	}

	if (loading) {
		return <Loading onBack={props.onBack} />;
	}

	if (selectingPlayers) {
		return <SelectPlayers 
			listOfPlayers={playersList} 
			guestPlayers={guestPlayers} 
			addGuestPlayer={addGuestPlayer}
			onRemoveGuest={onRemoveGuest}
			onBack={onBack} 
			onAccept={onFinishedSelecting}
			/>
	}

	return (
		<div className="cardArea">
			<DateRow editable={true} defaultVal={gameDate}/>
				<p style={playersHeader}>Players:</p>
				<div style={{overflowY:'scroll', height:'100%'}}>
				<MultiplePlayerRemove list={playersList} />
				<PlayersList players={guestPlayers.map(x=>({id:x.id, name:x.name + ' (guest)', removable:true}))} onRemove={onRemoveGuest} />
				</div>
				<div className="centeredBtn doneBtn">
					<p onClick={handleClickAddPlayers}>ADD PLAYERS</p>
				</div>
			<Footer acceptHidden={!enoughPlayersSelected()} onBack={props.onBack} onAccept={onAccept} acceptText="Create game"/>
		</div>
	)
}
