import React, { useState } from 'react'
import PointsGrid from './PointsGrid'
import APIAccessor from '../../util/APIAccessor';
import SetPoints from './SetPoints';
import { useEffect } from 'react';
import Footer from '../home/Footer';
import Loading from '../shared/Loading';

export default function Game({game_id, onBack, onCloseGame, hideDoneButton, onGameNotFound}) {

	const [settingPoints, setSettingPoints] = useState(false);
	const [game, setGame] = useState(null);
	const [body, setBody] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	function handleError(error) {
		if (error.response) {
			console.error(error.response.data);
			if (error.response.data) {
				alert(error.response.data);
			}
		}
	}

	useEffect(() => {
		APIAccessor.getGame(game_id)
		.then((results) => {
			setGame(results);
			setIsLoading(false);
		})
		.catch((error) => {
			handleError(error);
			onCloseGame();
			onGameNotFound && onGameNotFound();
		});
	}, [game_id]);

	async function reloadGame() {
		return APIAccessor.getGame(game_id);
	}

	function onSetPoints(player_id, player_name, round_number, points, did_kabool) {
		setBody({player_id, player_name, round_number, points, did_kabool});
		setSettingPoints(true);
	}

	function onDone(points, did_kabool) {
		APIAccessor.setRoundInGame(game_id, body.player_id, body.round_number, points, did_kabool)
		.then((res) => {
			setSettingPoints(false);
			reloadGame()
				.then((results) => {
					setGame(results);				
				})
				.catch((error) => {
					handleError(error);
				})
		})
		.catch((error) => {
			handleError(error);
		});
	}

	const handleOnAccept = function () {
		if (game.finished === false) {
			APIAccessor.finishGame(game_id)
			.then(() => {
				reloadGame()
				.then((results) => {
					setGame(results);					
				})
				.catch((error) => {
					handleError(error);
				})
			})
			.catch((error) => {
				handleError(error);
			})
		} else {
			onCloseGame();
		}
	}

	function allPointsSet() {
		const playerCount = game.players.length;
		return game.rounds.filter(round => round.length !== playerCount).length === 0;
	}

	function finishGameVisible() {
		return !game.finished && allPointsSet();
	}

	function doneButtonVisible() {
		return game.finished && !hideDoneButton;
	}

	function showAccept() {
		return finishGameVisible() || doneButtonVisible();
	}


	if (isLoading) {
		return  <Loading onBack={onBack}/>
	}

	return (
		<div className="cardArea">
			{settingPoints? 
				<SetPoints onDone={onDone} did_kabool={body.did_kabool} points={body.points} name={body.player_name}/> : 
				<PointsGrid game={game} onSetPoints={onSetPoints}/>}
			{settingPoints === false? <Footer onBack={onBack} acceptText={game.finished? "done" : "finish game"} onAccept={handleOnAccept} acceptHidden={!showAccept()}/> : null}
			
		</div>
	)
}
