import React from 'react'
import PlayerListElement from './PlayerListElement';

const playersList = {
	maxWidth: '100%',
	maxHeight: '100%',
	overflow: 'scroll',
	paddingBottom: '1em'
}

export default function PlayersList({players, onSelect, onDeselect, onRemove}) {

	const onClick = (event) => {
		event.preventDefault();
		event.stopPropagation();
	}

	function handleClick(id, action, event) {
		event.preventDefault();
		event.stopPropagation();
		
		switch (action) {
			case 'select':
				onSelect(id);
				break;
			case 'deselect':
				onDeselect(id);
				break;
			case 'remove':
				onRemove(id);
				break;
			default:
				break;
		}
		return onClick;
	}

	return (
		<div style={playersList}>
			<ul onClick={onClick}>
				{players.map(player => (
					<PlayerListElement key={player.id} {...player} handleClick={handleClick}/>
				))}
			</ul>
		</div>
	)
}
