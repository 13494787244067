import React, { useState } from 'react';
import { COLOR_KABOOL, ICON_CHECK_MARK, COLOR_STANDARD_TEXT } from '../../util/constants';
import { useHistory, useLocation } from 'react-router-dom';

const rowStyle = {
	display: 'flex'
}

const checkMarkBaseStyle = {width: '100%', textAlign: 'center'}


export default function Consent() {
	const statisticsCookiesEnabled = window.localStorage.getItem('statisticsCookies') === 'true';
	const [statistics, setStatistics] = useState(statisticsCookiesEnabled);
	const history = useHistory();

	const location = useLocation();

	function toggleStatistics() {
		window.localStorage.setItem('statisticsCookies', !statistics);
		setStatistics(!statistics);
	}

	function handleDone(e) {
		window.localStorage.setItem('consent', 'true');
		if (location.pathname === '/consent') {
			history.push('/home' + location.search);
		}
		window.location.reload();
	}

	return (
		<div className="consentPage">
			<h3>Cookie Consent</h3>
			<p>By using this site, you agree to the use of functional cookies</p>
			<div style={rowStyle} onClick={toggleStatistics}>
				<p style={{fontSize: '12px', textAlign: 'center'}} >Help developers by enabling statistics cookies?</p>
				<p style={statistics? {...checkMarkBaseStyle,color:COLOR_KABOOL} : {...checkMarkBaseStyle,color:COLOR_STANDARD_TEXT}}>{ICON_CHECK_MARK}</p>
			</div>
			<p style={{fontSize: '12px'}}>More information can be found in our <span className="doneBtn"><a href={'/privacy' + location.search}>privacy policy</a></span></p>
			<div onClick={handleDone} style={{marginTop:'2em'}} className="doneBtn centeredBtn">
				CONTINUE
			</div>
			
		</div>
	)
}
