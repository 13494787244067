import React from 'react';
import {COLOR_KABOOL,COLOR_STANDARD_TEXT, COLOR_STANDARD_BUTTON} from '../../util/constants';
import PointsCell from './PointsCell';

const divStyle = {
	display: 'grid',
	gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr 1fr 2fr',
	gridRowGap: '0.5em',
	textAlign: 'center',
	fontSize: '21px',
}

const rowStyle = {
	display: 'contents',
	color: '#707070'
}

const topRowStyle = {
	display: 'contents',
	fontWeight: 'bold',
}

const innerRowStyle = {
	display: 'contents',
}

const cellStyle = {
	color: COLOR_STANDARD_TEXT,
	margin: 'unset'
}

const cellDidKaboolStyle = {
	color: COLOR_KABOOL,
	margin: 'unset'
}

const firstColumnStyle = {
	textAlign: 'left',
	margin: 'unset'
}

const gameWinnerStyle = {
	color: COLOR_KABOOL
}

const getRoundResultsForPlayer = function (game, player_id, round_number) {
	let roundResultsForPlayer = game.rounds[round_number - 1].filter(x=>x.player_id === player_id);
	if (roundResultsForPlayer.length === 0) {
		return null;
	} else {
		return roundResultsForPlayer[0];
	}
}

const getPts = function(game, player_id, round_number){
	let roundResultsForPlayer = getRoundResultsForPlayer(game, player_id, round_number);
	if (roundResultsForPlayer !== null) {
		return roundResultsForPlayer.points;
	} else {
		return null;
	}
}
const didPlayerKInRound = function(game, player_id, round_number){
	let roundResultsForPlayer = getRoundResultsForPlayer(game, player_id, round_number);
	if (roundResultsForPlayer !== null) {
		return roundResultsForPlayer.did_kabool;
	} else {
		return false;
	}
}

const didPlayerWin = function (game, player_id) {
	return game.winners.find(x => x.player_id === player_id);
}

const getGameResultsForPlayer  = function (game, player_id) {
	let gameResultsForPlayer = game.game_results.find(x => x.player_id === player_id);
	if (gameResultsForPlayer === undefined) {
		return null;
	} else {
		return gameResultsForPlayer;
	}
}

const getStyleForTotalRow =function (game, player_id) {
	try {
		let baseStyle = {};
		return  getGameResultsForPlayer(game, player_id).most_kabools === true ? {...gameWinnerStyle, ...baseStyle}: baseStyle;
	} catch (error) {
		return {};
	}
}

const getFinalPointsForPlayer = function (game, player_id) {
	let gameResultsForPlayer = getGameResultsForPlayer(game, player_id);
	if (gameResultsForPlayer === null) {
		return ''
	} else {
		return gameResultsForPlayer.final_points;
	}
}

const getCrown = function (game, player_id) {
	return didPlayerWin(game,player_id)? `👑` : null;
}

const getNumberOfFirstRoundWithoutAllPoints = function (game) {
	const numberOfPlayers = game.players.length;

	return game.rounds
	.map((round, index )=> {round.number= index + 1; return round;})
	.filter(round => round.length < numberOfPlayers)
	.map(round => round.number)[0];
}

const isCurrentRound = function (game, round_number) {
	return round_number === getNumberOfFirstRoundWithoutAllPoints(game);
}

const getCellContents = function (game, player_id, player_name, round_number, onCellClicked) {
	let pointsInRoundForPlayer = getPts(game, player_id, round_number);
	let style = {};
	let did_kabool = didPlayerKInRound(game, player_id, round_number);
	let clickAction = game.finished? null :  (e) => onCellClicked(e, player_id, player_name, round_number, isNaN(pointsInRoundForPlayer)? null: pointsInRoundForPlayer, did_kabool);
	if (pointsInRoundForPlayer === null) {
		style = {color: COLOR_STANDARD_BUTTON};
		const currentRound = isCurrentRound(game, round_number);
		pointsInRoundForPlayer = currentRound? '+' : ' ';
		clickAction = currentRound ? clickAction : null;
	} else {
		style = did_kabool? cellDidKaboolStyle : cellStyle;
	}
	return <p key={round_number+player_id} style={style} onClick={clickAction}>{pointsInRoundForPlayer}</p>;
}

export default function PointsGrid({game, onSetPoints}) {

	function onCellClicked(event, player_id, player_name, round_number, points, did_kabool) {
		event.stopPropagation();
		onSetPoints(player_id, player_name, round_number, points, did_kabool);
	}

	return (
		<div style={divStyle}>
			<div style={{...rowStyle, ...topRowStyle}}>
				<p style={firstColumnStyle}>Player</p>
				<p >1</p> 
				<p >2</p>
				<p >3</p>
				<p >4</p>
				<p >5</p>
				<p >T</p>
			</div>
			{game.players.map(({player_id,player_name})=> (
				<div key={player_id} style={rowStyle}>
					<p style={firstColumnStyle}>{player_name}&nbsp;{getCrown(game, player_id)}</p>
					<div style={innerRowStyle}> 
						{[1,2,3,4,5].map(x => getCellContents(game, player_id, player_name, x, onCellClicked))}
					</div>
					<p style={getStyleForTotalRow(game, player_id)}><PointsCell points={getFinalPointsForPlayer(game, player_id)} /></p>
				</div>
			))}
		</div>
	)
}
