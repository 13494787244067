import React from 'react';
import Footer from '../home/Footer';
import MultiplePlayerSelect from '../shared/MultiplePlayerSelect';
import CreateGuest from './CreateGuest';
import PlayersList from './PlayersList';

const playersHeader = {
	fontWeight: 'bold',
	marginTop: '1em',
	marginBottom: '0.3em'
}

export default function SelectPlayers(props) {	

	function addGuestPlayer(name) {
		console.log('add guest player with name: ' + name);
		props.addGuestPlayer(name);
	}

	return (
		<div className="cardArea">
			<p style={playersHeader}>Registered Users:</p>
			<MultiplePlayerSelect list={props.listOfPlayers} />
			<p style={playersHeader}>Guest players:</p>
			<PlayersList players={props.guestPlayers.map(x=>({...x, removable:true}))} onRemove={props.onRemoveGuest} />
			<CreateGuest onAccept={addGuestPlayer} />
			<Footer onBack={props.onBack} onAccept={props.onAccept} acceptText="done"/>
		</div>
	)
}
