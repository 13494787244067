import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {BrowserRouter} from 'react-router-dom'

const statisticsCookiesEnabled = window.localStorage.getItem('statisticsCookies') === 'true';
const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
		disableExceptionTracking: true,
		enableCorsCorrelation:  true,
		isCookieUseDisabled: !statisticsCookiesEnabled
	}
});

console.log(document.cookie.split(';'));
if (!statisticsCookiesEnabled) {
	document.cookie = "ai_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	document.cookie = "ai_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}
console.log('AppInsights cookie use disabled? ' + appInsights.config.isCookieUseDisabled);

console.log(process.env.REACT_APP_ENVIRONMENT);

appInsights.loadAppInsights();
//appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
const telemetryInitializer = (envelope) => {	
	envelope.tags["ai.cloud.role"] = 'kaboolapp-fe-' + process.env.REACT_APP_ENVIRONMENT;
}
appInsights.addTelemetryInitializer(telemetryInitializer);

ReactDOM.render(
  <React.StrictMode>
	  <BrowserRouter>
	  <App />
	  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
