import React, { useState } from 'react'
import { useEffect } from 'react';

export default function CreateGuest({onAccept}) {

	const [name, setName] = useState('');
	function handleChange(e) {
		let inputString = e.target.value + '';
		inputString = inputString.trim();
		setName(inputString)
	}

	const [valid, setValid] = useState(false);
	useEffect(() => {
		setValid(/^[A-Za-z0-9]{3,12}$/.test(name));
	}, [name]);

	function handleClick() {
		if (name === '') {
			return;
		}
		onAccept(name);
		setName('');
	}

	return (
		<div>
			<span className="centeredBtn">
				<input value={name} onChange={handleChange} type="text" name="guestName" id="guestName"/>
			</span>
			<div onClick={handleClick} className={valid? 'centeredBtn doneBtn': 'centeredBtn'}><p>Add guest user</p></div>
		</div>
	)
}
