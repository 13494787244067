import React, {useState} from 'react'
import { useLocation} from 'react-router-dom'
import queryString from 'query-string';

const translations = {
  en: {
      summary: "In this card game, each player aims to have the lowest score by the end of 5 rounds. Players start with 4 face-down cards and can peek at some of their cards at the beginning of each round. On their turn, players draw a card and decide whether to keep it, swap it, or use its effect. Players can place cards face up in the middle and compete to stack matching cards. If a player believes they have a very low score, they can declare 'Kabool' to end the game early, with potential penalties for not having the lowest score.",
      setup: "Each player starts with 4 cards placed face down in two rows in front of them. At the beginning of each round (a total of 5 rounds), each player is allowed to peek at the 2 cards in their bottom row.",
      turns: [
          "Players take turns counter-clockwise.",
          "On their turn, a player draws a card from the deck.",
          "The player can choose to keep the card or discard it.",
          "If the player keeps the card, they can swap it with one of their own face-down cards (without looking at it).",
          "Alternatively, the player can choose to play the card's effect by placing it face up in the middle of the table.",
          "Playing the card's effect is optional. If the player chooses not to play the card, they can discard it instead."
      ],
      cardPlacement: [
          "When a player places a card face up in the middle, any other player with the same card (regardless of color) can place their card on top of it.",
          "The card placement must be quick. If another player places their card first, the slower player must take their card back.",
          "The player who successfully places their card first can then add all other cards they know of to the middle, including those of opponents."
      ],
      kaboolRule: [
          "If a player believes they have a very low score, they can declare 'Kabool!' on their turn instead of drawing a card.",
          "After declaring 'Kabool,' a final round is played starting with the next player in turn (the player who said 'Kabool' does not play in this final round).",
          "Once the final round is complete, all players reveal their cards.",
          "If the player who declared 'Kabool' does not have the lowest score, they receive 5 penalty points added to their current score.",
          "If the player who declared 'Kabool' has more than 5 points, their total score is doubled."
      ],
      endOfGame: [
          "The game ends after the fifth round.",
          "Scores from each round are added to determine the total score for each player.",
          "The player who declared 'Kabool' the most times throughout the game receives a deduction of 5 points.",
          "If there is a tie for the most 'Kabool' declarations, both tied players receive a deduction of 3 points each.",
          "The player with the lowest total score at the end of the game wins."
      ],
      cardRules: [
          { rank: 'Ace', effect: 'No effect', points: 1 },
          { rank: '2', effect: 'No effect', points: 2 },
          { rank: '3', effect: 'No effect', points: 3 },
          { rank: '4', effect: 'No effect', points: 4 },
          { rank: '5', effect: 'No effect', points: 5 },
          { rank: '6', effect: 'No effect', points: 6 },
          { rank: '7', effect: 'Peek at one of your cards', points: 7 },
          { rank: '8', effect: 'Same as above', points: 8 },
          { rank: '9', effect: 'Peek at one of your opponent\'s cards', points: 9 },
          { rank: '10', effect: 'Same as above', points: 10 },
          { rank: 'Jack', effect: 'Swap one of your cards with an opponent\'s card without looking at either card', points: 10 },
          { rank: 'Queen', effect: 'Swap one of your cards with an opponent\'s card without looking at either card', points: 10 },
          { rank: 'Black King', effect: 'Look at both your card and an opponent\'s card, then choose whether to swap them or not', points: 10 },
          { rank: 'Red King', effect: 'No effect', points: -1 },
          { rank: 'Joker', effect: 'No effect', points: 0 }
      ]
    },
    es: {
        summary: "En este juego de cartas, cada jugador busca tener la puntuación más baja al final de 5 rondas. Los jugadores comienzan con 4 cartas boca abajo y pueden echar un vistazo a algunas de sus cartas al comienzo de cada ronda. En su turno, los jugadores sacan una carta y deciden si quieren conservarla, intercambiarla o usar su efecto. Los jugadores pueden colocar cartas boca arriba en el centro y competir para apilar cartas iguales. Si un jugador cree que tiene una puntuación muy baja, puede declarar 'Kabool' para terminar el juego antes, con posibles penalizaciones si no tiene la puntuación más baja.",
        setup: "Cada jugador comienza con 4 cartas colocadas boca abajo en dos filas frente a ellos. Al comienzo de cada ronda (un total de 5 rondas), cada jugador puede mirar las 2 cartas en su fila inferior.",
        turns: [
            "Los jugadores toman turnos en sentido antihorario.",
            "En su turno, un jugador saca una carta del mazo.",
            "El jugador puede decidir si quiere conservar la carta o descartarla.",
            "Si el jugador conserva la carta, puede intercambiarla con una de sus propias cartas boca abajo (sin mirarla).",
            "Alternativamente, el jugador puede optar por jugar el efecto de la carta colocándola boca arriba en el centro de la mesa.",
            "Jugar el efecto de la carta es opcional. Si el jugador elige no jugar la carta, puede descartarla en su lugar."
        ],
        cardPlacement: [
            "Cuando un jugador coloca una carta boca arriba en el centro, cualquier otro jugador con la misma carta (independientemente del color) puede colocar su carta encima de ella.",
            "La colocación de la carta debe ser rápida. Si otro jugador coloca su carta primero, el jugador más lento debe recuperar su carta.",
            "El jugador que coloque su carta primero puede entonces añadir todas las demás cartas que conoce al centro, incluidas las de los oponentes."
        ],
        kaboolRule: [
            "Si un jugador cree que tiene una puntuación muy baja, puede declarar 'Kabool!' en su turno en lugar de sacar una carta.",
            "Después de declarar 'Kabool', se juega una ronda final comenzando con el siguiente jugador en turno (el jugador que dijo 'Kabool' no juega en esta ronda final).",
            "Una vez que la ronda final esté completa, todos los jugadores revelan sus cartas.",
            "Si el jugador que declaró 'Kabool' no tiene la puntuación más baja, recibe 5 puntos de penalización añadidos a su puntuación actual.",
            "Si el jugador que declaró 'Kabool' tiene más de 5 puntos, su puntuación total se duplica."
        ],
        endOfGame: [
            "El juego termina después de la quinta ronda.",
            "Las puntuaciones de cada ronda se suman para determinar la puntuación total de cada jugador.",
            "El jugador que declaró 'Kabool' más veces durante el juego recibe una deducción de 5 puntos.",
            "Si hay un empate en el mayor número de declaraciones de 'Kabool', ambos jugadores empatados reciben una deducción de 3 puntos cada uno.",
            "El jugador con la puntuación total más baja al final del juego gana."
        ],
        cardRules: [
            { rank: 'As', effect: 'Sin efecto', points: 1 },
            { rank: '2', effect: 'Sin efecto', points: 2 },
            { rank: '3', effect: 'Sin efecto', points: 3 },
            { rank: '4', effect: 'Sin efecto', points: 4 },
            { rank: '5', effect: 'Sin efecto', points: 5 },
            { rank: '6', effect: 'Sin efecto', points: 6 },
            { rank: '7', effect: 'Mira una de tus cartas', points: 7 },
            { rank: '8', effect: 'Mira una de tus cartas', points: 8 },
            { rank: '9', effect: 'Mira una carta de tu oponente', points: 9 },
            { rank: '10', effect: 'Mira una carta de tu oponente', points: 10 },
            { rank: 'J', effect: 'Intercambia una de tus cartas por una carta de un oponente sin mirar ninguna de las dos', points: 10 },
            { rank: 'Q', effect: 'Intercambia una de tus cartas por una carta de un oponente sin mirar ninguna de las dos', points: 10 },
            { rank: 'K Negra', effect: 'Mira tanto tu carta como una carta de un oponente y decide si quieres intercambiarlas o no', points: 10 },
            { rank: 'K Roja', effect: 'Sin efecto', points: -1 },
            { rank: 'Joker', effect: 'Sin efecto', points: 0 }
        ]
    },
    de: {
        summary: "In diesem Kartenspiel zielt jeder Spieler darauf ab, am Ende von 5 Runden die niedrigste Punktzahl zu haben. Die Spieler beginnen mit 4 Karten, die verdeckt vor ihnen liegen, und können zu Beginn jeder Runde einige ihrer Karten ansehen. In ihrem Zug zieht ein Spieler eine Karte und entscheidet, ob er sie behalten, tauschen oder ihren Effekt nutzen möchte. Die Spieler können Karten offen in die Mitte legen und versuchen, gleiche Karten zu stapeln. Wenn ein Spieler glaubt, dass er eine sehr niedrige Punktzahl hat, kann er 'Kabool' sagen, um das Spiel vorzeitig zu beenden, wobei mögliche Strafen drohen, wenn er nicht die niedrigste Punktzahl hat.",
        setup: "Jeder Spieler beginnt mit 4 Karten, die verdeckt in zwei Reihen vor ihm liegen. Zu Beginn jeder Runde (insgesamt 5 Runden) darf jeder Spieler die 2 Karten in seiner unteren Reihe ansehen.",
        turns: [
            "Die Spieler spielen reihum im Uhrzeigersinn.",
            "In seinem Zug zieht ein Spieler eine Karte vom Stapel.",
            "Der Spieler kann entscheiden, ob er die Karte behalten oder ablegen möchte.",
            "Wenn der Spieler die Karte behält, kann er sie gegen eine seiner verdeckten Karten tauschen (ohne sie anzusehen).",
            "Alternativ kann der Spieler den Effekt der Karte nutzen, indem er sie offen in die Mitte des Tisches legt.",
            "Das Spielen des Effekts der Karte ist optional. Wenn der Spieler sich entscheidet, die Karte nicht zu spielen, kann er sie stattdessen ablegen."
        ],
        cardPlacement: [
            "Wenn ein Spieler eine Karte offen in die Mitte legt, kann jeder andere Spieler mit derselben Karte (unabhängig von der Farbe) seine Karte oben drauf legen.",
            "Das Ablegen der Karte muss schnell erfolgen. Wenn ein anderer Spieler zuerst seine Karte legt, muss der langsamere Spieler seine Karte zurücknehmen.",
            "Der Spieler, der seine Karte zuerst abgelegt hat, kann dann alle anderen Karten, die er kennt, in die Mitte legen, einschließlich der Karten von Gegnern."
        ],
        kaboolRule: [
            "Wenn ein Spieler glaubt, dass er eine sehr niedrige Punktzahl hat, kann er in seinem Zug anstelle einer Karte zu ziehen 'Kabool!' sagen.",
            "Nach der Erklärung von 'Kabool' wird eine letzte Runde gespielt, die mit dem nächsten Spieler im Zug beginnt (der Spieler, der 'Kabool' gesagt hat, spielt in dieser letzten Runde nicht mehr mit).",
            "Sobald die letzte Runde abgeschlossen ist, decken alle Spieler ihre Karten auf.",
            "Wenn der Spieler, der 'Kabool' gesagt hat, nicht die niedrigste Punktzahl hat, erhält er 5 Strafpunkte zusätzlich zu seiner aktuellen Punktzahl.",
            "Wenn der Spieler, der 'Kabool' gesagt hat, mehr als 5 Punkte hat, wird seine Gesamtpunktzahl verdoppelt."
        ],
        endOfGame: [
            "Das Spiel endet nach der fünften Runde.",
            "Die Punkte aus jeder Runde werden addiert, um die Gesamtpunktzahl jedes Spielers zu ermitteln.",
            "Der Spieler, der während des Spiels am häufigsten 'Kabool' gesagt hat, erhält einen Abzug von 5 Punkten.",
            "Wenn es einen Gleichstand bei der Anzahl der 'Kabool'-Rufe gibt, erhalten beide gleichwertigen Spieler einen Abzug von 3 Punkten.",
            "Der Spieler mit der niedrigsten Gesamtpunktzahl am Ende des Spiels gewinnt."
        ],
        cardRules: [
            { rank: 'As', effect: 'Kein Effekt', points: 1 },
            { rank: '2', effect: 'Kein Effekt', points: 2 },
            { rank: '3', effect: 'Kein Effekt', points: 3 },
            { rank: '4', effect: 'Kein Effekt', points: 4 },
            { rank: '5', effect: 'Kein Effekt', points: 5 },
            { rank: '6', effect: 'Kein Effekt', points: 6 },
            { rank: '7', effect: 'Sieh dir eine deiner Karten an', points: 7 },
            { rank: '8', effect: 'Sieh dir eine deiner Karten an', points: 8 },
            { rank: '9', effect: 'Sieh dir eine Karte deines Gegners an', points: 9 },
            { rank: '10', effect: 'Sieh dir eine Karte deines Gegners an', points: 10 },
            { rank: 'Bube', effect: 'Tausche eine deiner Karten gegen eine Karte eines Gegners, ohne eine der Karten anzusehen', points: 10 },
            { rank: 'Dame', effect: 'Tausche eine deiner Karten gegen eine Karte eines Gegners, ohne eine der Karten anzusehen', points: 10 },
            { rank: 'Schwarzer König', effect: 'Sieh dir sowohl deine Karte als auch eine Karte eines Gegners an und entscheide dann, ob du sie tauschen möchtest oder nicht', points: 10 },
            { rank: 'Roter König', effect: 'Kein Effekt', points: -1 },
            { rank: 'Joker', effect: 'Kein Effekt', points: 0 }
        ]
    }
};


export default function Rules() {

  const location = useLocation();
  console.log('RUKESKRUKLES');
  location.query = queryString.parse(location.search);
  console.log(location.query);

  const [language, setLanguage] = useState('en');

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    const { summary, setup, turns, cardPlacement, kaboolRule, endOfGame, cardRules } = translations[language];

    return (
        <div className="game-rules">
          <div className='container'>
            <h1>Game Rules</h1>
            <select value={language} onChange={handleLanguageChange}>
                <option value="en">English</option>
                <option value="es">Spanish</option>
                <option value="de">German</option>
            </select>
            
            <section className="summary">
                <h2>Summary</h2>
                <p>{summary}</p>
            </section>
            
            <section className="setup">
                <h2>Setup</h2>
                <p>{setup}</p>
            </section>

            <section className="turns">
                <h2>Turns</h2>
                <ul>
                    {turns.map((turn, index) => <li key={index}>{turn}</li>)}
                </ul>
            </section>

            <section className="card-placement">
                <h2>Card Placement</h2>
                <ul>
                    {cardPlacement.map((placement, index) => <li key={index}>{placement}</li>)}
                </ul>
            </section>

            <section className="kabool-rule">
                <h2>Kabool Rule</h2>
                <ul>
                    {kaboolRule.map((rule, index) => <li key={index}>{rule}</li>)}
                </ul>
            </section>

            <section className="end-of-game">
                <h2>End of Game</h2>
                <ul>
                    {endOfGame.map((end, index) => <li key={index}>{end}</li>)}
                </ul>
            </section>

            <section className="card-rules">
                <h2>Card Rules</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Effect</th>
                            <th>Points</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cardRules.map((card, index) => (
                            <tr key={index}>
                                <td>{card.rank}</td>
                                <td>{card.effect}</td>
                                <td>{card.points}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
      </div>
      {
        location.query.mobile === 'true' ? '' :
          <a href={'/home' + location.search}><div className="centeredBtn doneBtn"><p>BACK HOME</p></div></a>
      }

    </div>
  )
}
