import React from 'react'
import { COLOR_STANDARD_TEXT, COLOR_STANDARD_BUTTON, COLOR_STANDARD_DANGER, COLOR_KABOOL, ICON_CHECK_MARK } from '../../util/constants'

const listElementStyles = {
	padding: '0.3em 1em 0.7em 0em',
	display: 'flex',
	justifyContent: 'space-between',
	fontSize: '21px',
}

const nameStyles = {
	fontWeight: 500,
	color: COLOR_STANDARD_TEXT
}

const selectableStyles = {
	color: COLOR_STANDARD_BUTTON
}

const removableStyles = {
	color: COLOR_STANDARD_DANGER
}

const selectedStyle = {
	color: COLOR_KABOOL
}

export default function PlayerListElement({id, name, selectable, selected, removable, handleClick}) {

	function handleOnClick(e) {
		let action = selectable ? 'select' : selected ? 'deselect' : removable ? 'remove' : '';
		handleClick(id, action, e);
	}

	return (
		<li key={id} style={listElementStyles} onClick={handleOnClick}>
			<p style={nameStyles}>{name}</p>
			{selectable ? <p style={selectableStyles}>+</p> : null}
			{selected ? <p style={selectedStyle}>{ICON_CHECK_MARK}</p> : null}
			{removable ? <p style={removableStyles}>x</p> : null}
		</li>
	)
}
