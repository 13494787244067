import React, { useEffect } from 'react'
import Footer from '../home/Footer'
import GoogleIcon from './btn_google_light_normal.svg';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function Login(props) {
	const location = useLocation();
	location.query = queryString.parse(location.search);

	const history = useHistory();
	useEffect(() => {
		history.replace('/profile/login' + location.search);
	}, [history]);

	function handleClick(e){
		let baseUrl = process.env.REACT_APP_BASE_URL + '/auth/google?type=login';
		if (location.query.next) {
			baseUrl = baseUrl + '&next=' + location.query.next;
		}
		window.location.replace(baseUrl);
	}

	return (
		<div className="cardArea">
			<div style={{display:'flex', flexDirection:'column', height:'100%', alignItems:'center', justifyContent:'space-around'}}>
			<p>Log in with:</p>
			<div className="signInBtn" onClick={handleClick}>
				<span className="signInIcon" style={{backgroundImage: `url(${GoogleIcon})`}} ></span>
				<span className="signInBtnText" style={{color:'#444'}} >Google</span>
			</div>
			<p style={{fontSize: '12px'}}>Not registered yet? <span className="doneBtn" onClick={props.onRegisterClicked}>Create an account</span></p>
			</div>
			<Footer onBack={props.onBack} acceptHidden={true}/>
		</div>
	)
}
