import React from 'react'
import { screens } from '../../util/constants';

const styles = {
	fontSize: '3em',
    fontWeight: 'bold',
    color: '#707070',
	letterSpacing: '-0.05em',
	margin: 'unset',
	marginTop: '0.3em'
};

export default function Title(props) {
	
	const content = {};
	content[screens.home] = 'Kabool !';
	content[screens.profile] = 'Profile';
	content[screens.createGame] = 'New Game';
	content[screens.stats] = 'Stats';
	content[screens.games] = 'Games';

	function handleClick(e) {
		props.onBack();
	}
	return (
		<p onClick={handleClick} style={styles}>{content[props.screen]}</p>
	)
};